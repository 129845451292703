// BOILERPLATE FOR PAGES
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PageWrapper from "../PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

const BaseIndex = ({ data }) => {

    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges
  
    return (
  
      <PageWrapper>

        <Header component={<Hero/>} title={ siteTitle } />

        <SEO title="All posts" />

        {/* MAIN CONTENT */}
  
        <Footer/>
  
      </PageWrapper>
    )
}

export default BaseIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`

